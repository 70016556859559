import React from 'react'

import Image from 'next/image'

import { useWillsModalContext } from '@/contexts/WillsModalContext'
import { Button } from '@getethos/ethos-design-system-v2'
import { CloudinaryImage } from 'ethos-design-system'
import { cn } from 'lib/client-utils'

import useNewWillsHeroSection from '@/hooks/features/useNewWillsHeroSection'

import WillsModal from '../Wills/WillsModal'
import { SingleCTAInterface } from '../interface'

const bullets = [
  'No expensive lawyers. No appointments.',
  'Start and finish your will in minutes.',
  'Confirm who will care for your children.',
]

const trustImagesV1 = [
  {
    imageUrl:
      'https://res.cloudinary.com/getethos/image/upload/v1739442724/Trustpilot_Micro_h013e3.png',
    alt: 'Trustpilot',
  },
  {
    imageUrl:
      'https://res.cloudinary.com/getethos/image/upload/v1739442724/bbb_logo_sq1jxo.png',
    alt: 'BBB',
  },
]

const trustImagesV2 = [
  {
    imageUrl:
      'https://res.cloudinary.com/getethos/image/upload/v1739447331/Number_and_text_rlc4uq.png',
    alt: 'Trustpilot',
  },
  {
    imageUrl:
      'https://res.cloudinary.com/getethos/image/upload/v1739448151/Number_and_text2_qwxabb.png',
    alt: 'EstatePlanning',
  },
  {
    imageUrl:
      'https://res.cloudinary.com/getethos/image/upload/v1739448466/Number_and_text3_ul7yey.png',
    alt: 'BBB rating',
  },
]

const WillsV3 = ({
  moduleData,
  ctaClick,
}: {
  moduleData: SingleCTAInterface
  ctaClick: () => void
  policiesApproved?: number
}) => {
  const { isTreatment1 } = useNewWillsHeroSection()
  const { setIsModalOpen } = useWillsModalContext()
  const { ctaLabel } = moduleData

  const handleCTAClick = () => {
    if (moduleData.disableNavigateToApp) {
      setIsModalOpen(true)
    }
    ctaClick()
  }

  const trustWorthIcons = isTreatment1 ? (
    <TrustWorthIconsV1 trustImages={trustImagesV1} />
  ) : (
    <TrustWorthIconsV2 trustImages={trustImagesV2} />
  )

  return (
    <>
      <div className="m-auto flex w-full flex-col justify-between gap-[40px] text-black md:max-w-[1440px] lg:flex-row lg:px-0 [&_p]:m-0">
        <div className="relative flex flex-col px-[16px] lg:pl-[80px]">
          <nav className="flex w-full py-[28px]">
            <div className="max-h-[16px] max-w-[86px] lg:absolute lg:left-[64px] lg:top-[32px]">
              <CloudinaryImage
                className="h-[16px] w-[86px]"
                publicId="https://res.cloudinary.com/getethos/image/upload/v1739433638/Ethos-Logo-Cypress_prnmdp.svg"
                alt="Ethos logo"
                crop={CloudinaryImage.CROP_METHODS.CROP}
                height={[16, 16, 16, 16]}
                width={[86, 86, 86, 86]}
              />
            </div>
          </nav>
          <div className="relative flex max-w-[567px] flex-col self-center lg:self-start">
            <div className="mb-[8px] mt-[8px] flex h-[32px] w-fit select-none items-center self-center rounded-[64px] bg-[rgb(5,98,87)]/[0.2] p-[16px] font-[NewTheinhardt] text-[14px] text-[#044742] lg:mb-[16px] lg:mt-[32px] lg:self-start">
              The No. 1 estate planning platform
            </div>
            <div className="relative z-1 mx-auto">
              <h1 className="font-['Cambon'] text-[48px] font-normal tracking-[-1.44px] lg:text-[64px] lg:tracking-[-1.92px]">
                <span className="text-[#044742]">Creating a will</span> has
                never been easier
              </h1>
              <ul className="bullets mb-[32px] mt-6 flex list-none flex-col gap-y-[16px] p-0">
                {bullets.map((bullet) => (
                  <li
                    key={bullet}
                    className="flex items-start gap-x-[12px] gap-y-[8px] text-base tracking-[-0.28px] text-[#404040]"
                  >
                    <div className="h-6 w-6">
                      <CheckmarkIcon />
                    </div>
                    <span>{bullet}</span>
                  </li>
                ))}
              </ul>
              <Button
                className="flex w-full !rounded-[999px] uppercase !text-black lg:max-w-[260px]"
                onClick={handleCTAClick}
                buttonTitle={ctaLabel}
                size="lg"
                variant="salamander"
              />
            </div>
            {trustWorthIcons}
          </div>
        </div>

        <div className="w-fill hidden h-auto items-start lg:flex">
          <CloudinaryImage
            publicId="https://res.cloudinary.com/getethos/image/upload/v1739433721/family-picture_lpmxvl.png"
            alt="Family"
            crop={CloudinaryImage.CROP_METHODS.FIT}
            height={[754, 754, 754, 754]}
            width={[665, 665, 665, 665]}
          />
        </div>
        <div className="flex h-auto w-full items-start bg-cover bg-no-repeat lg:hidden">
          <Image
            src="https://res.cloudinary.com/getethos/image/upload/v1739436032/family_wuatza.png"
            className="!relative"
            alt="Family"
            fill
          />
        </div>
      </div>
      <WillsModal />
    </>
  )
}

const TrustWorthIconsV1 = ({
  trustImages,
}: {
  trustImages: { imageUrl: string; alt: string }[]
}) => {
  return (
    <div className="mb-[40px] mt-[48px] flex max-h-[70px] max-w-[250px] items-center gap-x-[40px] self-center lg:mb-[100px] lg:mt-[40px] lg:max-h-[90px] lg:max-w-[365px] lg:gap-x-[28px] lg:self-start">
      {trustImages.map((trustImage) => (
        <div
          className={cn('flex items-center')}
          key={`trustImage-${trustImage.imageUrl}`}
        >
          <div className="flex items-center">
            <CloudinaryImage
              className="h-full"
              publicId={trustImage.imageUrl}
              alt={trustImage.alt}
              crop={CloudinaryImage.CROP_METHODS.FIT}
              height={[85, 85, 85, 85]}
              width={[200, 200, 200, 200]}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

const TrustWorthIconsV2 = ({
  trustImages,
}: {
  trustImages: { imageUrl: string; alt: string }[]
}) => {
  return (
    <div className="mb-[40px] mt-[48px] flex max-h-[112px] w-full max-w-[360px] items-center self-center lg:mb-[54px] lg:mt-[64px] lg:max-h-[90px] lg:max-w-[600px] lg:self-start">
      {trustImages.map((trustImage) => (
        <div
          className="flex max-h-[112px] max-w-[120px]  items-center lg:max-h-[112px] lg:max-w-[200px]"
          key={`trustImage-${trustImage.imageUrl}`}
        >
          <div className="flex items-center">
            <CloudinaryImage
              className="h-full"
              publicId={trustImage.imageUrl}
              alt={trustImage.alt}
              crop={CloudinaryImage.CROP_METHODS.FIT}
              height={[112, 112, 112, 112]}
              width={[200, 200, 200, 200]}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

const CheckmarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M8.79995 15.8998L4.59995 11.6998L3.19995 13.0998L8.79995 18.6998L20.8 6.6998L19.4 5.2998L8.79995 15.8998Z"
      fill="#044742"
    />
  </svg>
)

export default WillsV3
