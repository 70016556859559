import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import get from 'lodash.get'
import identity from 'lodash.identity'
import pickBy from 'lodash.pickby'

import { CreditScore, Gender, Health, Smoker } from '../constants'
import { ExtraUserData, UserData } from '../interfaces/'
import { ageFromBirthDate } from './ageFromBirthDate'
import { parseAffiliateQueryParam } from './utils'

dayjs.extend(customParseFormat)

const parseZipCode = (zipCode: string): string | undefined => {
  // ensure that it is an integer and is of length 5
  if (!Number.isInteger(Number(zipCode)) || String(zipCode).length !== 5) {
    return
  }

  return zipCode
}

const parseGender = (gender: string): string | undefined => {
  if (!gender || typeof gender !== 'string') {
    return
  }

  switch (gender.toLowerCase()) {
    case 'm':
    case 'male':
      return Gender.Male
    case 'f':
    case 'female':
      return Gender.Female
    default:
      return
  }
}

const parseHealth = (health?: string): string | undefined => {
  if (!health) {
    return
  }

  switch (health.toLowerCase()) {
    case '1':
    case 'average':
      return Health.Average
    case '2':
    case 'great':
      return Health.Great
    case '3':
    case 'excellent':
      return Health.Excellent
    default:
      return
  }
}

const parseSmoker = (smoker?: string): string | undefined => {
  if (!smoker) {
    return
  }

  switch (smoker.toLowerCase()) {
    case 'true':
    case '1':
    case 'y':
      return Smoker.True
    case 'false':
    case '0':
    case 'n':
      return Smoker.False
    default:
      return
  }
}

export const parseBirthDate = (birthDate?: string): string | undefined => {
  if (!birthDate) {
    return
  }

  if (Number.isFinite(Number(birthDate))) {
    return birthDate
  }

  return String(ageFromBirthDate({ birthDate }))
}

const parseCreditScore = (creditScore: string): string | undefined => {
  if (!creditScore || typeof creditScore !== 'string') {
    return
  }

  switch (creditScore.toLowerCase()) {
    case 'excellent':
      return CreditScore.High
    case 'good':
    case 'average':
      return CreditScore.Average
    case 'below average':
    case 'poor':
      return CreditScore.Low
    default:
      return
  }
}

// Handle default string-based parameters
const parseString = (apiParam: string): string | undefined => {
  if (!apiParam || typeof apiParam !== 'string') {
    return
  }

  return apiParam
}

/**
 * Given a list of query params, extracts them and maps them to a corresponding value,
 * returning the first defined mapping. For instance, if you need to get smoker,
 * you can pass ['tobacco', 'smoker'] and parseSmoker and it will return a value
 * if either exists.
 * @param queryParams
 * @param urlParams
 * @param parseFn
 * @returns
 */
const extractQueryParam = (
  queryParams: any,
  urlParams: string[],
  parseFn: (param: string) => string | undefined
): string | undefined => {
  // maps the query params to the right value and filters undefined values
  const extractedValues = urlParams
    .map((param) => parseFn(get(queryParams, param)))
    .filter((val) => val != null)

  if (!extractedValues || extractedValues.length === 0) {
    return
  }

  return extractedValues[0]
}

/**
 * Maps query params to user data that isn't used elsewhere
 */
const mapParsedQueryToExtraData = (
  queryParams: Record<string, any>
): Partial<ExtraUserData> => {
  const address = extractQueryParam(
    queryParams,
    ['address', 'utm_address'],
    parseString
  )
  const addressTwo = extractQueryParam(
    queryParams,
    ['address_2', 'utm_address_2'],
    parseString
  )
  const city = extractQueryParam(queryParams, ['city', 'utm_city'], parseString)
  const email = extractQueryParam(
    queryParams,
    ['email', 'utm_email'],
    parseString
  )
  const firstName = extractQueryParam(
    queryParams,
    ['first_name', 'utm_first_name', 'fname', 'utm_fname'],
    parseString
  )
  const lastName = extractQueryParam(
    queryParams,
    ['last_name', 'utm_last_name', 'lname', 'utm_lname'],
    parseString
  )
  const middleName = extractQueryParam(
    queryParams,
    ['middle_name', 'utm_middle_name', 'mname', 'utm_mname'],
    parseString
  )
  const name = extractQueryParam(
    queryParams,
    ['name', 'utm_name', 'contact', 'utm_contact'],
    parseString
  )
  const phone = extractQueryParam(
    queryParams,
    ['phone', 'utm_phone'],
    parseString
  )
  const state = extractQueryParam(
    queryParams,
    ['state', 'utm_state'],
    parseString
  )

  const utmSource = extractQueryParam(
    queryParams,
    ['utm_source', 'utmSource'],
    parseString
  )

  const extraUserData = {
    address,
    addressTwo,
    city,
    email,
    firstName,
    lastName,
    middleName,
    name,
    phone,
    state,
    utmSource,
  }

  const cleanedObject = pickBy(extraUserData, identity)

  return cleanedObject
}

/**
 * Maps query params to user data
 */
export const mapParsedQueryToUserData = (
  queryParams: Record<string, any>
): Partial<UserData> => {
  const smoker = extractQueryParam(
    queryParams,
    ['smoker', 'tobacco', 'utm_tobacco', 'utm_smoker'],
    parseSmoker
  )
  const birthDate = extractQueryParam(
    queryParams,
    [
      'birthDate',
      'birth_date',
      'utm_dob',
      'dob',
      'utm_birth_date',
      'utm_birthdate',
      'birthdate',
      'age',
      'utm_age',
      'utmAge',
    ],
    parseBirthDate
  )
  const gender = extractQueryParam(
    queryParams,
    ['gender', 'utm_gender'],
    parseGender
  )
  const health = extractQueryParam(
    queryParams,
    ['health', 'utm_health'],
    parseHealth
  )
  const zipCode = extractQueryParam(
    queryParams,
    ['zip', 'utm_zipcode', 'zipcode', 'zip_code', 'utm_zip_code', 'utm_zip'],
    parseZipCode
  )

  const estimatedCredit = extractQueryParam(
    queryParams,
    [
      'creditScore',
      'credit_rating',
      'credit_score',
      'utm_credit_score',
      'utm_creditscore',
      'utm_credit_rating',
      'utm_creditrating',
      'creditscore',
      'creditrating',
    ],
    parseCreditScore
  )

  const extraUserData = mapParsedQueryToExtraData(queryParams)
  const userParams: UserData = {
    birthDate,
    estimatedCredit,
    gender,
    health,
    smoker,
    zipCode,
  }
  if (Object.keys(extraUserData).length !== 0) {
    userParams.extraUserData = extraUserData
  }

  // removes undefined or null objects
  const cleanedObject = pickBy(userParams, identity)

  return cleanedObject
}

const SUPPORTED_AFFILIATE_PARAMS = ['param1', 'p.param1']

export const mapQueryToUserData = (
  queryParams: Record<string, any>
): Partial<UserData> => {
  for (const masterParam of SUPPORTED_AFFILIATE_PARAMS) {
    if (get(queryParams, masterParam)) {
      const extracted = parseAffiliateQueryParam(queryParams, masterParam)
      const parsedQuery = mapParsedQueryToUserData(extracted)

      if (!Object.values(parsedQuery).every((x) => x === null)) {
        return parsedQuery
      }
    }
  }

  return mapParsedQueryToUserData(queryParams)
}
